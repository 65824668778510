<template>
  <el-dialog
    title="选择KOL收货地址"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :visible.sync="kolAddressVisible"
    append-to-body
    width="80%"
    :before-close="cancel"
  >
    <vxe-table
      ref="xTable"
      class="xTable"
      max-height="550px"
      align="center"
      border
      :data="kolTableData"
      :scroll-x="{enabled: true}"
      :scroll-y="{enabled: true}"
      :row-config="{isHover: true}"
      :column-config="{resizable: true}"
      :radio-config="{strict: false}"
      @radio-change="radioChangeEvent"
    >
      <vxe-table-column type="radio" width="60">
        <template #header>
          <span>选择</span>
        </template>
      </vxe-table-column>
      <vxe-table-column field="name" title="收件人" min-width="100" />
      <vxe-table-column field="country" title="国家" min-width="80" />
      <vxe-table-column field="state" title="州/区" min-width="80" />
      <vxe-table-column field="city" title="城市" min-width="100" />
      <vxe-table-column field="address1" title="地址一" min-width="200" />
      <vxe-table-column field="address2" title="地址二" min-width="200" />
      <vxe-table-column field="postcode" title="邮编" min-width="150" />
      <vxe-table-column field="phone" title="电话" min-width="150" />
    </vxe-table>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="submit">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    kolAddressVisible: {
      type: Boolean,
      default: false
    },
    kolTableData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectList: {}
    }
  },
  methods: {
    radioChangeEvent({ newValue }) {
      this.selectList = newValue || null
    },
    cancel() {
      this.$emit('update:kolAddressVisible', false)
    },
    submit() {
      this.cancel()
      this.$emit('updeteCustomerInfo', this.selectList)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
