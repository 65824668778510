<template>
  <div>
    <el-upload
      ref="upload"
      :accept="accept"
      :action="uploadAPI"
      :before-upload="handleBeforeUpload"
      :class="{hide:hideUploadEdit}"
      :data="formData"
      :file-list="fileList"
      :headers="headers"
      :limit="limit"
      :list-type="listType"
      :multiple="multiple"
      :on-error="handleError"
      :on-exceed="handleExceed"
      :on-preview="handlePictureCardPreview"
      :on-progress="handleProgress"
      :on-remove="handleRemove"
      :on-success="handleSuccess"
      v-bind="$attrs"
    >
      <slot>
        <i class="el-icon-plus" />
      </slot>
    </el-upload>
    <el-dialog :append-to-body="true" :visible.sync="dialogVisible">
      <img :src="dialogImageUrl" alt="" width="100%">
    </el-dialog>
  </div>
</template>
<script>
const innerFlag = '__fromUploadCom__'

const getFileLimitMessage = (max) => {
  const kb = 1024
  const m = kb * 1024
  let msg
  if (max < kb) {
    msg = ` ${max} B`
  } else if (max < m) {
    msg = ` ${max / kb} KB`
  } else {
    msg = ` ${max / m} M`
  }
  return msg
}

/**
 * 图片上传
 * 可用的事件：
 *  input: 图片上传的值变化事件，接收一个数组，结构同 value 相同
 */
export default {
  name: 'Upload',
  props: {
    /**
     * 文件列表
     * {
     *  fileUrl: {string}, 相对地址
     *  signatureUrl: {string}，绝对地址(带域名)
     *  name: {string} 文件名，可选
     * }
     */
    value: {
      type: Array,
      default: () => []
    },
    action: {
      type: String,
      default: ''
    },
    params: {
      type: Object,
      default: () => ({})
    },
    beforeUpload: {
      type: Function,
      default: () => true
    },
    onSuccess: {
      type: Function,
      default: null
    },
    // 是否多张上传
    multiple: {
      type: Boolean,
      default: false
    },
    // 上传个数
    limit: {
      type: Number,
      default: 1
    },
    // 上传类型
    listType: {
      type: String,
      default: 'picture-card'
    },
    // 文件限制格式限制
    accept: {
      type: String,
      default: '.gif,.jpg,.jpeg,.png,.bmp,'
    },
    // 上传大小限制,单位为 b
    // 传递 0 则不限制
    sizeLimit: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      uploadAPI: this.action,
      fileList: [],
      // 上传图片数。包含上传中的图片，这些图片上传可能失败。用于 hideUploadEdit。
      fileNum: 0,
      dialogImageUrl: '',
      dialogVisible: false,
      headers: {
        authorization: this.$store.getters.authorization
      }
    }
  },
  computed: {
    hideUploadEdit() {
      return this.fileNum >= (this.limit || Infinity)
    },
    formData() {
      return {
        ...this.params
      }
    }
  },
  watch: {
    value() {
      this.setFileList()
    }
  },
  created() {
    this.setFileList()
  },
  methods: {
    clearFiles() {
      this.fileList = []
      this.fileNum = 0
      this.$refs.upload.clearFiles()
      this.$emit('input', [])
    },
    // 根据外部 value 值设置 fileList
    setFileList() {
      // 判断数据更新是否是从内部发起的
      if (this.value[innerFlag]) {
        delete this.value[innerFlag]
        return
      }
      if (Array.isArray(this.value)) {
        this.fileList = this.value.map(item => ({
          name: item.name,
          url: item.signatureUrl
        }))
        this.fileNum = this.fileList.length
      }
    },
    // 删除上传的文件
    handleRemove(file, fileList) {
      const list = [...this.value]
      const uid = file.uid
      const signatureUrl = file.url
      let index
      for (let i = this.value.length - 1; i >= 0; i--) {
        if (uid === this.value[i].uid || signatureUrl === this.value[i].signatureUrl) {
          index = i
          list.splice(i, 1)
        }
      }
      list[innerFlag] = true
      this.$emit('input', list)
      this.$emit('remove', file, fileList, list, index)
      this.fileNum = list.length
    },
    handlePictureCardPreview(file) {
      if (this.listType !== 'text') {
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      }
    },
    handleProgress(event, file, fileList) {
      this.$emit('progress', event, file, fileList)
    },
    async handleSuccess(response, file, fileList) {
      this.$emit('finish')
      if (response.success && (response.data || response.datas)) {
        const data = response.data || response.datas
        const list = [...this.value]
        list.push({
          name: file.name,
          uid: file.uid,
          size: file.size,
          fileUrl: data,
          signatureUrl: data
        })
        list[innerFlag] = true
        this.$emit('input', list)
        this.fileNum = list.length
        await this.onSuccess?.(response, file, fileList)
        this.$message.success(this.$t('title.ImportSuccess'))
      } else {
        this.handleTipErrorMsg(response.error || response.msg)
      }
    },
    handleError() {
      this.$emit('finish')
      this.fileNum = this.value.length
      this.handleTipErrorMsg()
    },
    handleTipErrorMsg(msg) {
      this.$message({
        type: 'error',
        message: msg || this.$t('title.UploadError')
      })
    },
    handleExceed(file, fileList) {
      this.$message({
        message: this.$t('title.LimitTip', { limit: this.limit }),
        type: 'warning'
      })
    },
    checkFileType(file) {
      if (!this.accept) return true
      const accept = this.accept?.split(',') || []
      const type = file.name.split('.').pop()
      const isAccept = accept.some(item => {
        if (item === 'image/*') {
          const imgList = ['svgz', 'pjp', 'png', 'ico', 'avif', 'tiff', 'tif', 'jfif', 'svg', 'xbm', 'pjpeg', 'webp', 'jpg', 'jpeg', 'bmp', 'gif']
          return imgList.find(item => item === type?.toLowerCase())?.length
        }
        if (item === 'video/*') {
          const videoList = ['mp4', 'mov', 'avi', 'wmv', 'flv', 'rmvb', 'mkv', '3gp', 'mpg', 'mpeg']
          return videoList.find(item => item === type?.toLowerCase())?.length
        }
        if (item === 'audio/*') {
          const audioList = ['mp3', 'wav', 'wma', 'ogg', 'flac', 'aac', 'ape']
          return audioList.find(item => item === type?.toLowerCase())?.length
        }
        return accept.find(item => item?.split('.').pop()?.toLowerCase() === type?.toLowerCase())?.length
      })
      if (!isAccept) {
        this.$message({
          message: this.$t('title.FileTypeTip', { accept: this.accept }),
          type: 'warning'
        })
      }
      return isAccept
    },
    handleBeforeUpload(file) {
      return new Promise(async(res, rej) => {
        // 用户上传文件大小超出限制
        if (this.sizeLimit !== 0) {
          if (file.size > this.sizeLimit) {
            const limit = getFileLimitMessage(this.sizeLimit)
            this.$message({
              type: 'warning',
              message: this.$t('title.SizeLimitTip', { limit })
            })
            rej()
          }
        }
        const isAccept = this.checkFileType(file)
        if (!isAccept) {
          rej()
        }
        const result = await this.beforeUpload?.(file)
        if (!result) {
          rej()
        }
        this.fileNum++
        this.$nextTick(() => {
          res()
        })
      })
    }
  }
}
</script>

<style>
.hide .el-upload--picture-card {
  display: none;
}
</style>
