<template>
  <div style="padding: 0 10px">
    <el-row>
      <el-col :span="20">{{ $t('order.Orderinformation') }}</el-col>
    </el-row>
    <hr>
    <el-form ref="orderForm" :model="orderForm" :rules="rules" label-width="130px">
      <el-row :span="24">
        <el-col :span="6">
          <el-form-item :class="$i18n.locale" :label="$t('title.ordernumber')">
            <el-input v-model="orderForm.transportCode" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('order.types')" prop="orderType">
            <el-select v-model="orderForm.orderType" :placeholder="$t('page.selectPlaceholder')" :disabled="interetCelebrityId!==''" filterable reserve-keyword>
              <el-option v-for="item in _getAllCommodityDict('SELF_ORDER_TYPE')" :key="item.val" :label="item.label" :value="item.val" />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item :label="$t('page.status')">
            <el-select v-model="orderForm.orderStatus" :placeholder="$t('page.selectPlaceholder')" disabled>
              <el-option :label="$t('order.Cancel')" value="0" />
              <el-option :label="$t('order.Awaitingreview')" value="1" />
              <el-option :label="$t('order.Systemreviewed')" value="2" />
              <el-option :label="$t('order.Shipped')" value="3" />
              <el-option :label="$t('order.Arrived')" value="4" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('title.platform')" prop="platform">
            <el-select v-model="orderForm.platform" :placeholder="$t('page.selectPlaceholder')" clearable filterable value-key="id" @change="platforChange">
              <el-option
                v-for="item in platforoptions"
                :key="item.id"
                :label="item.platformCode"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('title.site')" prop="site">
            <el-select v-model="orderForm.site" :placeholder="$t('page.selectPlaceholder')" clearable filterable value-key="id" @change="siteChange">
              <el-option
                v-for="item in siteoptions"
                :key="item.id"
                :label="item.siteName"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <!-- 店铺 -->
        <el-col :span="6">
          <el-form-item :label="$t('order.Sellingaccount')" prop="merchant">
            <el-select v-model="orderForm.merchant" :placeholder="$t('page.selectPlaceholder')" clearable filterable @change="merchantChange">
              <el-option
                v-for="(item,index) in shopoptions"
                :key="index"
                :label="item.shopCode"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('route.CustomerInformation')">
            <el-select v-model.trim="orderForm.customerId" :placeholder="$t('page.selectPlaceholder')">
              <el-option
                v-for="item in customerList"
                :key="item.id"
                :label="item.customerName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-if="+orderForm.orderType === 27" :span="6">
          <el-form-item :label="$t('page.DesignatedWarehouse')" prop="warehouseCode">
            <el-select v-model.trim="orderForm.warehouseCode" :placeholder="$t('page.selectPlaceholder')">
              <el-option
                v-for="item in warehouseList"
                :key="item.warehouseCode"
                :label="item.warehouseName"
                :value="item.warehouseCode"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :class="$i18n.locale" :label="$t('order.Shippingnotes')">
            <el-input v-model="orderForm.remark" :placeholder="$t('page.inputPlaceholder')" type="textarea" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="20">
          <span>{{ $t('title.CustomerInfo') }}</span>
          <el-button v-if="interetCelebrityId" class="el-icon-plus" style="margin-left:15px" type="text" @click="selectKOLAddress">{{ $t('order.KOLAddress') }}</el-button>
        </el-col>
      </el-row>
      <hr>
      <el-row>
        <el-col :span="6">
          <el-form-item :label="$t('order.Receipt')" prop="receiveName">
            <el-input v-model.trim="orderForm.receiveName" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('order.Country')" prop="countryCode">
            <el-input v-model.trim="orderForm.countryCode" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('order.Statesd')" prop="stateRegion">
            <el-input v-model.trim="orderForm.stateRegion" :placeholder="$t('order.Pleaseshortname')" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('title.city')" prop="city">
            <el-input v-model.trim="orderForm.city" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('order.Address')" prop="addressLine1">
            <el-input v-model.trim="orderForm.addressLine1" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('order.address')">
            <el-input v-model.trim="orderForm.addressLine2" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('order.Zipcode')" prop="postalCode">
            <el-input v-model.trim="orderForm.postalCode" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('title.phone')" prop="receivePhone">
            <el-input v-model.trim="orderForm.receivePhone" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="Email">
            <el-input v-model.trim="orderForm.buyerEmail" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- 添加SKU -->
    <el-row>
      <el-col :span="6">
        <span>{{ $t('order.Orderinformations') }}</span>
        <el-button class="el-icon-plus" style="margin-left:15px" type="text" @click="showDialog = true">{{ $t('order.AddSUK') }}</el-button>
      </el-col>
    </el-row>
    <el-table
      :data="orderData"
      :header-cell-style="{background:'#fafafa'}"
      :row-class-name="tableRowClassName"
      class="mb-3"
      max-height="600px"
      @cell-dblclick="tabClick"
    >
      <el-table-column
        :label="$t('page.No')"
        align="center"
        type="index"
        width="70"
      >
        <template #default="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column align="center" :label="$t('SimpleManual.ProductImage')" property="mainImgUrl" width="120">
        <template #default="{row}">
          <el-image
            v-if="row.mainImgUrl"
            :preview-src-list="[row.mainImgUrl]"
            :src="row.mainImgUrl"
            fit="contain"
            lazy
            style="width: 100px; height: 100px"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" label="SKU" property="sku" />
      <el-table-column :label="$t('order.Buyerinformation')" align="center" property="sku" width="250">
        <template #default="scope">
          <span>{{ scope.row.styleName }}</span>
          <span v-if="scope.row.color">
            {{ '/' + scope.row.color }}
          </span>
          <span v-if="scope.row.size">{{ '/' + scope.row.size }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('order.Quantityenter')" align="center" property="address">
        <template #default="scope">
          <span v-if="scope.row.index === tabClickIndex && (tabClickLabel === '数量(双击输入数量)'|| tabClickLabel === 'Quantity(Double click to enter)')">
            <el-input
              v-model="scope.row.itemQuantity"
              :placeholder="$t('page.inputPlaceholder')"
              maxlength="300"
              oninput="value=value.replace(/[^0-9.]/g,'')"
              size="mini"
              @blur="inputBlur"
            />
          </span>
          <span v-else>{{ scope.row.itemQuantity }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('order.AmountEnter')" align="center" property="address">
        <template #default="scope">
          <span v-if="scope.row.index === tabClickIndex && tabClickLabel === $t('order.AmountEnter')">
            <el-input
              v-model="scope.row.itemPrice"
              :placeholder="$t('page.inputPlaceholder')"
              maxlength="300"
              oninput="value=value.replace(/[^0-9.]/g,'')"
              size="mini"
              @blur="inputBlur"
            />
          </span>
          <span v-else>{{ scope.row.itemPrice }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('page.operate')"
        align="center"
        fixed="right"
        width="150"
      >
        <template #default="scope">
          <!-- 删除 -->
          <span>
            <el-button size="small" type="text" @click="handleDelete(scope.$index, scope.row)">{{ $t('page.delete') }}</el-button>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-row :span="24" class="mt-3" style="text-align: center">
      <!-- <el-button @click="goBack">返 回</el-button> -->
      <el-button :loading="submitLoading" @click="cancle">{{ $t('page.empty') }}</el-button>
      <el-button :disabled="isabled" :loading="submitLoading" type="primary" @click="submitOrderData">{{ $t('title.submit') }}</el-button>
    </el-row>
    <!-- 添加sku -->
    <el-dialog :title="$t('order.AddSUK')" :visible.sync="showDialog" top="5vh" width="90%">
      <el-form
        ref="form"
        :model="form"
        class="demo-form-inline"
        label-width="auto"
      >
        <el-row :gutter="10" :span="24">
          <el-col :span="3">
            <el-form-item label="BU">
              <el-select v-model="form.buId" clearable filterable value-key="id" @change="BUSelectChange">
                <el-option
                  v-for="item in BuOptions"
                  :key="item.id"
                  :label="item.buName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="Style">
              <el-select v-model="form.styleId" clearable filterable value-key="id" @change="styleSelectChange">
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :label="item.styleName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="Color">
              <el-select v-model="form.colorId" clearable filterable>
                <el-option
                  v-for="item in colorOptions"
                  :key="item.id"
                  :label="item.colorName"
                  :value="item.id"
                />
              </el-select>

            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="Size">
              <el-select v-model="form.sizeId" clearable filterable>
                <el-option
                  v-for="item in sizeOptions"
                  :key="item.id"
                  :label="item.size"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="SKU">
              <el-input v-model="form.sku" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="Asin">
              <el-input v-model="form.asin" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-form-item style="white-space: nowrap">
              <el-button type="primary" @click="handleQuery">{{ $t('page.search') }}</el-button>
              <el-button @click="importDialogVisible = true">{{ $t('page.import') }}</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-table :data="skuDatas" :row-class-name="tableRowClassName" max-height="400px" @cell-dblclick="tabClick">
        <el-table-column align="center" :label="$t('SimpleManual.ProductImage')" property="mainImgUrl" width="120">
          <template #default="{row}">
            <el-image
              v-if="row.mainImgUrl"
              :preview-src-list="[row.mainImgUrl]"
              :src="row.mainImgUrl"
              fit="contain"
              lazy
              style="width: 100px; height: 100px"
            />
          </template>
        </el-table-column>
        <el-table-column align="center" label="SKU" property="sku" />
        <el-table-column :label="$t('order.Buyerinformation')" align="center" property="sku">
          <template #default="scope">
            <span>{{ scope.row.styleName }}</span>
            <span v-if="scope.row.color">
              {{ '/' + scope.row.color }}
            </span>
            <span v-if="scope.row.size">{{ '/' + scope.row.size }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('order.Quantityenter')" align="center" property="address">
          <template #default="scope">
            <span v-if="scope.row.index === tabClickIndex && (tabClickLabel === '数量(双击输入数量)'|| tabClickLabel === 'Quantity(Double click to enter)')">
              <el-input
                v-model="scope.row.itemQuantity"
                :placeholder="$t('page.inputPlaceholder')"
                maxlength="300"
                oninput="value=value.replace(/[^0-9.]/g,'')"
                size="mini"
                @blur="inputBlur"
              />
            </span>
            <span v-else>{{ scope.row.itemQuantity }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-row :span="24" class="mt-3" style="text-align: center">
        <el-button @click="cancleShowDialog">{{ $t('title.cancel') }}</el-button>
        <el-button type="primary" @click="submitSkuData">{{ $t('title.submit') }}</el-button>
      </el-row>
    </el-dialog>
    <import-dialog :visible.sync="importDialogVisible" @success="handleImport" />
    <KolAddress :kol-address-visible.sync="kolAddressVisible" :kol-table-data="kolTableData" @updeteCustomerInfo="updeteCustomerInfo" />
  </div>
</template>

<script>
import KolAddress from './KolAddress'
import mixin from '@/mixin/oms-mixin'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import {
  getCustomerInfo,
  getDetailCelebrityById,
  getSkuByParams,
  getWarehouseList,
  queryBaseBuList,
  queryColorList,
  queryShopList,
  querySiteList,
  querySizeList,
  queryStyleList,
  queryTerraceList,
  saveOrUpdate,
  setCelebrityOrder
} from '@/api/omsorder'
import ImportDialog from './ImportDialog'

export default {
  components: { ImportDialog, KolAddress },
  mixins: [mixin, commodityInfoDict],
  data() {
    return {
      submitLoading: false,
      platforoptions: [],
      siteoptions: [],
      shopoptions: [],
      styleOptions: [],
      colorOptions: [],
      sizeOptions: [],
      customerList: [],
      warehouseList: [],
      showDialog: false,
      skuDatas: [],
      orderData: [],
      isabled: false,
      orderForm: {
        orderType: '',
        platform: '',
        site: '',
        merchant: '',
        orderStatus: '1',
        customerId: '',
        warehouseCode: ''
      },
      pager: {
        current: 1,
        size: 800,
        total: 0
      },
      form: {
        buId: '',
        styleId: '',
        colorId: '',
        sizeId: ''
      },
      platformId: '',
      siteId: '',
      shopId: '',
      tabClickIndex: '',
      tabClickLabel: '',
      orderdata: '',
      BuOptions: '',
      rules: {
        orderType: [{ required: true, message: this.$t('page.required'), trigger: ['blur', 'change'] }],
        platform: [{ required: true, message: this.$t('page.required'), trigger: ['blur', 'change'] }],
        site: [{ required: true, message: this.$t('page.required'), trigger: ['blur', 'change'] }],
        merchant: [{ required: true, message: this.$t('page.required'), trigger: ['blur', 'change'] }],
        receiveName: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        countryCode: [{ required: true, message: this.$t('page.required'), trigger: ['blur'] }],
        stateRegion: [{ required: true, message: this.$t('page.required'), trigger: ['blur'] }],
        city: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        addressLine1: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        postalCode: [{ required: true, message: this.$t('page.required'), trigger: ['blur'] }],
        receivePhone: [{ required: true, message: this.$t('page.required'), trigger: ['blur'] }],
        warehouseCode: [{ required: true, message: this.$t('page.required'), trigger: ['blur'] }]
      },

      kolAddressVisible: false,
      importDialogVisible: false,
      kolTableData: [],
      interetCelebrityId: '',
      taskId: ''
    }
  },
  computed: {
    // 生成对应的请求参数
    queryParameter() {
      return Object.assign({}, this.pager, this.form)
    }
  },
  watch: {
    'orderForm.customerId'(val) {
      if (val) {
        const customer = this.customerList.find(item => item.id === val)?.deliveryAddress
        if (customer) {
          const data = {
            ...customer,
            name: customer.receiveName,
            postcode: customer.zipCode
          }
          this.setOrderFormData(data)
        }
      }
    }
  },
  mounted() {
    this._queryStyleList()
    this._queryColorList()
    this._querySizeList()
    this._querySiteList()
    this._queryTerraceList()
    this._queryBaseBuList()
    this.queryCustomerList()
    this.getWarehouseList()

    this.interetCelebrityId = this.$route?.query?.interetCelebrityId || ''
    this.taskId = this.$route.query.taskId
    this.interetCelebrityId && (this.$set(this.orderForm, 'orderType', '5'))
  },
  methods: {
    async getWarehouseList() {
      const { data } = await getWarehouseList()
      this.warehouseList = data
    },
    async queryCustomerList() {
      const { datas } = await getCustomerInfo()
      this.customerList = datas || []
    },
    updeteCustomerInfo(val) {
      if (val) {
        const data = this.kolTableData.find(item => item.name === val.name && item.phone === val.phone)
        this.setOrderFormData(data)
      }
    },
    async selectKOLAddress() {
      const { datas: { addressDtoList = [] }} = await getDetailCelebrityById({ id: this.interetCelebrityId })
      if (addressDtoList.length && addressDtoList.length === 1) {
        this.setOrderFormData(addressDtoList[0])
      } else {
        this.kolTableData = addressDtoList
        this.kolAddressVisible = true
      }
    },
    /**
     * @param data
     * @param data.name
     * @param data.phone
     * @param data.country
     * @param data.state
     * @param data.city
     * @param data.address1
     * @param data.address2
     * @param data.postcode
     * @param data.email
     */
    setOrderFormData(data) {
      this.$set(this.orderForm, 'receiveName', data.name || '')
      this.$set(this.orderForm, 'countryCode', data.country || '')
      this.$set(this.orderForm, 'stateRegion', data.state || '')
      this.$set(this.orderForm, 'city', data.city || '')
      this.$set(this.orderForm, 'addressLine1', data.address1 || '')
      this.$set(this.orderForm, 'addressLine2', data.address2 || '')
      this.$set(this.orderForm, 'postalCode', data.postcode || '')
      this.$set(this.orderForm, 'receivePhone', data.phone || '')
      this.$set(this.orderForm, 'buyerEmail', data.email || '')
    },
    // 选择平台带出站点
    platforChange(val) {
      this.orderForm.platform = val.platformCode
      this.platformId = val.id
      this._querySiteList(this.platformId)
      this.orderForm.site = ''
      this.orderForm.merchant = ''
    },
    siteChange(val) {
      if (this.orderForm.platform === '' || this.orderForm.platform === undefined) {
        this.$message.error(this.$t('tips.Pleasechooseplatform'))
        this.orderForm.site = ''
      } else {
        this.orderForm.site = val.siteName
        this.siteId = val.id
        this._queryShopList({ siteId: this.siteId, sellPlatformId: this.platformId })
        this.orderForm.merchant = ''
      }
    },
    merchantChange(val) {
      this.orderForm.merchant = val.shopCode
      this.shopId = val.id
    },
    async _queryBaseBuList() {
      const { datas } = await queryBaseBuList()
      this.BuOptions = datas
    },
    async _queryTerraceList() {
      const { datas } = await queryTerraceList()
      this.platforoptions = datas
      this.platforoptions = this.platforoptions.filter((item) => {
        return item.platformCode === 'KOL'
      })
    },
    async _querySiteList(id) {
      // 这里传没用
      const { datas } = await querySiteList(id)
      this.siteoptions = datas
      this.siteoptions = this.siteoptions.filter((item) => {
        return item.siteName === 'US' || item.siteName === 'CA' || item.siteName === 'GB' || item.siteName === 'EU' || item.siteName === 'CN'
      })
      // this._queryShopList({ siteId: this.form.site, sellPlatformId: this.platformId })
    },

    async _queryShopList(data) {
      const { datas } = await queryShopList(data)
      this.shopoptions = datas
    },
    BUSelectChange(value) {
      this._queryStyleList(value)
    },
    async _queryStyleList(id) {
      const { datas } = await queryStyleList({ buId: id })
      this.styleOptions = datas
    },

    styleSelectChange(value) {
      const data = this.styleOptions.find(item => item.id === value)
      this._queryColorList(data.id)
      this._querySizeList(data.id)
      this.form.colorId = ''
      this.form.sizeId = ''
      // this.stockManageForm.style = this.stockManageForm.style.styleName
    },
    handleQuery() {
      this._getSkuByParams(this.queryParameter)
    },
    async _getSkuByParams(params) {
      const { datas } = await getSkuByParams(params)
      this.skuDatas = datas.records
    },
    // color信息获取
    async _queryColorList(id) {
      const { datas } = await queryColorList({ styleId: id })
      this.colorOptions = datas
    },
    async _querySizeList(id) {
      const { datas } = await querySizeList({ styleId: id })
      this.sizeOptions = datas
    },

    // table点击 row 当前行 column 当前列
    tabClick(row, column, cell, event) {
      this.tabClickIndex = row.index
      this.tabClickLabel = column.label
    },
    // 失去焦点初始化
    inputBlur(row, event, column) {
      this.tabClickIndex = null
      this.tabClickLabel = ''
    },
    tableRowClassName({ row, rowIndex }) {
      // 把每一行的索引放进row
      row.index = rowIndex
    },
    // 取消
    cancleShowDialog() {
      Object.assign(this.form, this.$options.data.call(this).form)
      this.skuDatas = []
      this.showDialog = false
    },
    // 清空
    cancle() {
      this.$refs.orderForm.resetFields()
      this.orderData = []
      this.orderForm.addressLine2 = ''
      this.orderForm.remark = ''
      this.orderForm.receivePhone = ''
      this.orderForm.buyerEmail = ''
      this.orderForm.transportCode = ''
      this.orderForm.customerId = ''
      this.isabled = false
    },
    handleImport(data) {
      this.skuDatas = data.map(item => {
        return {
          styleName: item.style,
          itemQuantity: item.num,
          sku: item.sku,
          itemPrice: item.itemPrice
        }
      })
    },
    // 提交sku至明细中
    submitSkuData() {
      if (this.skuDatas.length >= 0) {
        this.skuDatas.map(e => {
          const index = this.orderData.findIndex((i) => e.sku === i.sku)
          if (index === -1) {
            this.orderData.push(e)
          } else {
            this.orderData.splice(index, 1, e)
          }
        })
      }
      Object.assign(this.form, this.$options.data.call(this).form)
      this.skuDatas = []
      this.showDialog = false
    },
    handleDelete(index) { // 删除行数
      this.orderData.splice(index, 1)
    },
    // 确认提交手工建单
    submitOrderData() {
      this.$refs.orderForm.validate((valid) => {
        if (!valid) return false
        const parameter = Object.assign({}, this.orderForm)
        if (this.orderForm.site === 'US') {
          parameter.currency = 'USD'
        } else if (this.orderForm.site === 'CA') {
          parameter.currency = 'CAD'
        } else {
          parameter.currency = 'USD'
        }
        parameter.receivePhone = this.orderForm.receivePhone ? this.orderForm.receivePhone : '   '
        parameter.buyerPhone = parameter.receivePhone ? parameter.receivePhone : '    '
        parameter.items = this.orderData
        for (const key of parameter.items) {
          // 数量不填就不能建单成功
          if (!key.itemQuantity) {
            this.$message.warning(`${key.sku} ${this.$t('SimpleManual.QuantityCannotBeEmpty')}`)
            return false
          }
          // “类型”字段值为“经销商”开头的，提交时增加校验，不允许创建金额为0的手工发货单
          const arr = this._getAllCommodityDict('SELF_ORDER_TYPE')
          const typeLabel = arr.find(item => item.val === this.orderForm.orderType).label
          if ((typeLabel.startsWith('经销商') || typeLabel.startsWith('JSX')) && !+key.itemPrice) {
            this.$message.warning(this.$t('SimpleManual.PriceEmpty'))
            return false
          }
        }
        this._saveOrUpdate(parameter)
      })
    },
    async _saveOrUpdate(data) {
      try {
        this.submitLoading = true
        const { responseDesc, responseCode, data: datas } = await saveOrUpdate(data)
        // this.orderdata = datas
        this.orderForm.transportCode = datas
        if (this.taskId && responseCode === '0001') {
          await setCelebrityOrder({
            orderNumber: this.orderForm.transportCode,
            taskId: this.taskId,
            platform: this.orderForm.platform,
            site: this.orderForm.site,
            shop: this.orderForm.merchant,
            customerId: this.orderForm.customerId
          })
        }
        if (responseCode === '0001') {
          this.$notify({
            title: responseDesc,
            message: responseDesc,
            type: 'success'
          })
          this.submitLoading = false
          this.isabled = true
          // this.$router.push({
          //   path: '../order-center-manage',
          //   append: true,
          //   query: { 'orderNumber': data }
          // })
        }
      } catch (error) {
        this.submitLoading = false
      } finally {
        this.submitLoading = false
      }
    },
    goBack() {
      this.$router.push({
        path: '/order-manage/order-center-manage'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-upload--picture-card {
    width: 75px;
    height: 75px;
    cursor: pointer;
    line-height:75px;
}
.hide .el-upload--picture-card {
  display: none;
}

::v-deep .el-dialog__body {
  padding: 20px;
}
</style>
